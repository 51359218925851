var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Aside" } },
    [
      _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.CommonAsideShow,
                expression: "CommonAsideShow",
              },
            ],
            staticClass: "transition-box",
          },
          [
            _c(
              "el-aside",
              {
                attrs: {
                  width: _vm.CommonShrinkageStart
                    ? `${_vm.CollapseWidth}` + "px"
                    : `${_vm.AsideWidth}` + "px",
                },
              },
              [
                _c("div", { staticClass: "logo_box" }, [
                  _vm.CommonLogoUrl !== ""
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col justify-center items-center h-[100%]",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "h-8 pt-1 text-center" },
                            [
                              _c("el-image", {
                                style: _vm.CommonShrinkageStart
                                  ? "height: 80%"
                                  : "height: 100%",
                                attrs: {
                                  src: _vm.CommonLogoUrl,
                                  fit: "contain",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.CommonShrinkageStart
                            ? _c("h4", { staticClass: "text-lg" }, [
                                _vm._v("云卬科技"),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _c("div", { staticClass: "logo" }, [
                        _c("h5", [_vm._v("Logo")]),
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.CommonShrinkageStart ? 70 : 300) + "* 70"
                          ),
                        ]),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "menu_box" },
                  [
                    _c(
                      "el-menu",
                      {
                        attrs: {
                          "background-color": _vm.CommonMenuBackgroundColor,
                          "text-color": _vm.CommonMenuTextColor,
                          "active-text-color": _vm.CommonMenuActiveTextColor,
                          "unique-opened": _vm.CommonUniqueOpened,
                          collapse: _vm.CommonShrinkageStart,
                          "collapse-transition": _vm.CommonCollapseTransition,
                          "default-active": _vm.CommonDefaultActive,
                        },
                        on: { select: _vm.HandleMenuSelect },
                      },
                      [
                        _vm._l(_vm.CommonAsideMenusList, function (item) {
                          return [
                            item.children
                              ? [
                                  _c(
                                    "el-submenu",
                                    {
                                      key: item.index,
                                      attrs: { index: item.path },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _c("i", { class: item.Icon }),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.authName)),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._l(item.children, function (subItem) {
                                        return [
                                          subItem.children
                                            ? _c(
                                                "el-submenu",
                                                {
                                                  key: subItem.id,
                                                  attrs: {
                                                    index: subItem.path,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "title",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                subItem.authName
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                _vm._l(
                                                  subItem.children,
                                                  function (threeItem, i) {
                                                    return _c(
                                                      "el-menu-item",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          index: threeItem.path,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            threeItem.authName
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : subItem.show
                                            ? _c(
                                                "el-menu-item",
                                                {
                                                  key: subItem.index,
                                                  attrs: {
                                                    index: subItem.path,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(subItem.authName)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-menu-item",
                                    {
                                      key: item.index,
                                      attrs: { index: item.path },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _vm._v(_vm._s(item.authName)),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("i", { class: item.Icon })]
                                  ),
                                ],
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }