<!-- Header -->
<template>
  <div id="Header">
    <div class="header_box">
      <div class="header_left" :style="{height:CommonHeaderHeight}">
        <el-tooltip class="item" effect="dark" :content="CommonShrinkageStart?'菜单展开':'菜单收起'" placement="top-start">
          <el-button type="text" :icon="CommonShrinkageStart?'el-icon-s-unfold':'el-icon-s-fold'" @click="FoldingClick" />
        </el-tooltip>
      </div>
      <div class="header_right" :style="{height:CommonHeaderHeight}">
        <!-- <el-tooltip class="item" effect="dark" content="搜索" placement="top-start">
          <el-button type="text" icon="el-icon-search" @click="SearchClick" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="消息" placement="top-start">
          <el-button type="text" icon="el-icon-bell" @click="BellClick" />
        </el-tooltip> -->
        <el-dropdown trigger="click" @command="HandleCommand">
          <div class="avatar_box">
            <el-avatar class="avatar" icon="el-icon-user-solid" />
            admin<i class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="1"><i class="el-icon-user" />个人信息</el-dropdown-item>
            <el-dropdown-item command="2"><i class="el-icon-edit" />编辑信息</el-dropdown-item> -->
            <el-dropdown-item command="3"><i class="el-icon-switch-button" />退出账号</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  Logout_API // 登出
} from '@/api/Login' // 登录全部接口
export default {
  name: 'Header',
  data() {
    return {
      isFullscreen: false, // 是否全屏
      ColorPicker: '#000000'
    }
  },
  /* HTML DOM加载后马上执行的，如赋值*/
  computed: {
    // 引入全局头部模块
    ...mapState('Common', [
      'CommonHeaderHeight', // 默认头部高度
      'CommonButtonSize', // 头部按钮尺寸
      'CommonShrinkageStart' // 导航是否展开
    ])
  },

  /* 观察Vue实例上的数据变动 */
  watch: {},

  /* 模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图 */
  created() {},

  /* 模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作 */
  mounted() {},

  /* methods则必须要有一定的触发条件才能执行 */
  methods: {
    // 引入 Common->mapMutations
    ...mapMutations('Common', [
      'ChangeShrinkageStart'
    ]),
    // 菜单折叠
    FoldingClick() {
      this.ChangeShrinkageStart(!this.CommonShrinkageStart)
    },
    // 搜索
    SearchClick() {
      console.log('搜索')
    },
    // 消息
    BellClick() {
      console.log('消息')
    },
    // 下拉菜单监听
    HandleCommand(HandleCommand) {
      // HandleCommand: 1: 个人信息, 2: 编辑信息 3: 退出账号
      console.log('下拉菜单监听', HandleCommand)
      switch (HandleCommand) {
        // case '1':

        //   break
        // case '2':
        //   break
        case '3':
          this.LoginOut()
          break
        default:
          break
      }
    },
    // 退出登录
    LoginOut() {
      console.log('// 退出登录')
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.Logout_API_fun()
      }).catch(() => {
        this.$message({
          type: 'info',
          center: true,
          message: '已取消退出'
        })
      })
    },
    // 登出
    Logout_API_fun() {
      Logout_API().then(res => {
        if (res.code === 200) {
          window.sessionStorage.clear()
          this.$router.push('/')
          this.$message({
            type: 'success',
            center: true,
            message: '退出成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            center: true
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
 #Header{
  .header_box{
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    .header_left{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2vw;
    }
    .header_right{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 20px;
      .colorpicker{
        margin-right: 10px;
      }
      .avatar_box{
        cursor: pointer;
        display: flex;
        align-items: center;
        .avatar{
          margin:0 10px 0 20px;
        }
      }
    }
  }
 }
</style>
