// 请求拦截和响应拦截
import axios from "axios";
// 引入Nprogress进度条
// https://github.com/rstacruz/nprogress参考
import NProgress from "nprogress";
// 引入Nprogtess样式
import "nprogress/nprogress.css";
import router from "@/router";
import { Message } from "element-ui";
/**
 * axios请求拦截器
 * 在request拦截器中展示进度条
 * NProgress.start() // 展示进度条
 */
NProgress.configure({ showSpinner: false }); // 显示右上角螺旋加载提示
console.log("HttpServe", process.env.VUE_APP_BASE_API);
const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60,
});
HTTP.interceptors.request.use(
  (config) => {
    // console.log('request----config', config)
    NProgress.start(); // 展示进度条
    config.headers.Authorization = `Bearer ${window.sessionStorage.getItem(
      "Authorization"
    )}`;
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    // 在最后必须return
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 挂在vue原型对象上
/**
 * 为什这样？
 * 这样的话每一vue组件都能通过this直接访问到Http，进行请求
 * 实现全局变量
 * 在response拦截器中隐藏
 * NProgress.done()
 */
HTTP.interceptors.response.use(
  (response) => {
    NProgress.done();

    // 判断是否是文件，是的话进行下载
    const { data } = response;
    const isBlob = data instanceof Blob;
    if (isBlob) {
      const blob = new Blob([data], {
        type: "application/octet-stream;charset=utf-8",
      });
      const fileName = response.headers["content-disposition"];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURIComponent(fileName && fileName.split("=")[1]);
      link.click();
      window.URL.revokeObjectURL(link.href);
      return Promise.resolve();
    } else {
      if (response.status === 401) {
        window.sessionStorage.clear();
        router.push("/").catch((data) => {});
        return response.data;
      } else if (
        response.data.code === 401 ||
        response.data.errorCode == 50001
      ) {
        window.sessionStorage.clear();
        router.push("/").catch((data) => {});
        return response.data;
      } else if (
        (response.data.code === 500 || response.data.errorCode == 50001) &&
        (response.data.msg.includes("token已过期") ||
          response.data.msg.includes("登录已过期"))
      ) {
        window.sessionStorage.clear();
        router.push("/").catch((data) => {});
        return response.data;
      } else {
        // 在最后必须return
        return response.data;
      }
    }
  },
  (error) => {
    const data = error.response.data;
    const err = {
      code: data.errorCode || data.status,
      msg: data.userMessage,
      data: "",
    };
    if (error.response.status === 401) {
      window.sessionStorage.clear();
      router.push("/").catch((data) => {});
    } else {
      Message.error(data.userMessage);
    }
    return err;
  }
);

export function defineRequest(url, method = "GET", options = {}) {
  return async (data) => {
    try {
      const res = await HTTP({
        ...options,
        url: `${process.env.VUE_APP_BASE_API_API}${url}`,
        method,
        data: method === "GET" ? undefined : data,
        params: method === "GET" ? data : undefined,
      });
      if (!res) {
        return {
          code: "",
          msg: "",
          data: {
            msg: "",
          },
        };
      }
      if (res.code === 401 || res.errorCode === 50001) {
        window.sessionStorage.clear();
        router.push("/").catch((data) => {});
        return res;
      } else if (res.code && res.code !== 200) {
        // Message.error(res.msg)
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const data = error.response.data;
      const err = {
        code: data.errorCode,
        msg: data.userMessage,
        data: "",
      };
      if (error.response.status === 401) {
        window.sessionStorage.clear();
        router.push("/").catch((data) => {});
      } else {
        Message.error(data.userMessage);
      }
      return err;
    }
  };
}

export default HTTP;
