import mqtt from 'mqtt'
// import { getDeviceModelList, getMqttConfInfoBiz } from '@/api/device';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { defineRequest } from '@/utils/request';

const getDeviceModelList = defineRequest('/api/device-info/getDeviceModel', 'GET');
const getMqttConfInfoBiz = defineRequest('/api/device-info/getMqttConfInfoBiz', 'POST');

const deviceTypes = {
  '-1': '全部',
  'socket': '插座',
  'circuitBreaker': '断路器',
  'lightingSwitch': '照明开关',
  'thermostat': '温控器',
  'waterMeter': '水表',
  'electricityMeter': '电表',
  'other': '其它'
};

// 解析设备实时数据时字段值的映射，用于前端展示
const deviceFieldsStaticMap = {
  thermostat: { // 设备类型
    label: '温控器', // 设备类型（只作为阅读方便不会使用）
    WA: { // 设备模型号（用于更准确的区分如何解析，解析时依据设备类型+模型号来取要映射的内容）
      mode: { // 上报上来的数据字段
        hot: { // 上报上来的数据字段值
          icon: 'xxx', // 要映射到解析后数据的内容（在hot对象中的所有“内容”都会被映射到解析结果中的mode对象下）
        }
      }
    }
  }
};

let mqttClient;
let deviceModelConf = null;
// 发送消息时值的格式化规则
const messageValueFormatteRule = {};

// 获取订阅地址
export const getTopicStrFromDevice = (orgCode, { gatewayCode, gatewayType, deviceId, deviceType }) => {
  return `${orgCode}/${gatewayType}/${gatewayCode}/${deviceType}/${deviceId}/uplink`
}

// 获取mqtt信息
export const getMqttInfo = async () => {
  const res = await getMqttConfInfoBiz()
  const params = { username: res.data.userName, password: res.data.password }
  window.localStorage.setItem('mqttInfo', JSON.stringify(params))
  return params;
}
// 创建mqtt连接
export const getMqttClient = (param) => {
  if (mqttClient) {
    return mqttClient;
  } else {
    mqttClient = mqtt.connect('wss://emqx-test.iyunang.com:8084', {
      path: '/mqtt',
      clientId: 'mqttjs_' + Math.random().toString(16).substring(2, 8),
      ...param
    });
    window.addEventListener('beforeunload', () => {
      mqttClient.end();
      mqttClient = null;
    });
    return mqttClient;
  }
}

// 获取设备模型并解析
export const getDeviceModelConf = async () => {
  if (!deviceModelConf) {
    const res = await getDeviceModelList();
    const modelList = res.data || [];
    deviceModelConf = modelList.reduce((rs, current) => {
      const { param, ...rest } = current;
      rs[current.modelNumber] = {
        ...rest,
        rules: (param || []).filter(rule => rule.showInWeb && rule.showInWeb === 'true').reduce((r, rule) => {
          const { type, enum: valueEnums, code, msgCode, messageValueType, messageType } = rule;
          const fieldValueEnums = (valueEnums || []).reduce((argEnumRs, arg) => {
            if (type === 'switch') {
              if (arg.name === '开') {
                argEnumRs.cmdOn = arg.value;
              } else if (arg.name === '关') {
                argEnumRs.cmdOff = arg.value;
              }
            } else {
              const valueConf = get(deviceFieldsStaticMap, `${current.deviceType}.${current.modelNumber}.${code}`, {});
              argEnumRs[arg.value] = {
                label: arg.name,
                ...valueConf[arg.value] || {},
              }
            }
            return argEnumRs;
          }, {});

          // 构建发送消息时值的格式化规则
          messageValueFormatteRule[`${messageType}-${msgCode || code}`] = messageValueType;

          r[rule.code] = {
            pushMessageType: messageType,
            argEnum: fieldValueEnums,
            label: rule.name,
            unit: rule.unit || '',
            type: rule.type,
            pushMessageKey: msgCode || code,
            formatte: rule.formatte,
            max: rule.max ? parseFloat(rule.max) : undefined,
            min: rule.min ? parseFloat(rule.min) : undefined,
          }
          return r;
        }, {})
      };
      return rs;
    }, {});
  }
  return deviceModelConf;
}

// 根据formatte来格式化数值数据
const formatteNumericalData = (formatte, data) => {
  const rule = formatte.split(/:\s*/)[1] || '*_1';
  const [op, numerical] = rule.split('_');
  switch (op) {
    case '+':
      return (parseFloat(data || '0') + parseFloat(numerical)).toFixed(2);
    case '-':
      return (parseFloat(data || '0') - parseFloat(numerical)).toFixed(2);
    case '*':
      return (parseFloat(data || '0') * parseFloat(numerical)).toFixed(2);
    case '/':
      return (parseFloat(data || '0') / parseFloat(numerical)).toFixed(2);
  
    default:
      return '--';
  }
};

const circuitBreakerValueKeys = [
  'switch_status',
  'voltameter',
  'active_power',
  'power_factor',
  'A_temperature',
  'B_temperature',
  'C_temperature',
  'N_temperature',
];

// 一般化上报数据
const transformMessageData = (deviceData, modelConf) => {
  const { rules, deviceType } = modelConf;
  const keys = Object.keys(deviceData);
  // 从模型中获取需要解析的keys （包含断路器异化处理逻辑）
  const modelKeys = deviceType === 'circuitBreaker' ? circuitBreakerValueKeys : Object.keys(rules || {});
  // 要遍历的keys
  const needParseKeys = modelKeys.length ? modelKeys.filter(item => keys.includes(item)) : keys;

  const res = needParseKeys.reduce((r, key) => {
    const rule = rules[key];
    const { pushMessageType, formatte, argEnum, type, label, pushMessageKey } = rule || {};
    const value = deviceData[key];

    // 对于开关状态单独处理
    if (type === 'switch') {
      let isOnline = value.endsWith(argEnum.cmdOn);
      // 沃尔弗温控器特殊处理
      if (modelConf.manufacturer === '沃尔弗') {
        isOnline = deviceData['power'].endsWith(argEnum.cmdOn);
      }
      r.switchStatus.push({
        dataIndex: pushMessageKey || key,
        label,
        pushMessageType,
        isOnline,
        // isOnline: value.endsWith(argEnum.cmdOn),
        ...argEnum,
      });
    } else {
      let formattedValue;
      switch (true) {
        case !!formatte:
          {
            switch (true) {
              case formatte.startsWith('number'):
                formattedValue = formatteNumericalData(formatte, value);
                break;
              default:
                formattedValue = value;
                break;
            }
          }
          break;
        default: {
          let v = '';
          if (`${value}`.indexOf('.') > -1) {
            v = parseFloat(value)?.toFixed(2) || value;
          } else {
            v = parseInt(value) || value;
          }
          formattedValue = v;
        }
          break;
      }
      r.dataIndexs.push(key);
      r.data[key] = {
        dataIndex: pushMessageKey || key,
        label: rule?.label,
        value: formattedValue,
        transformedValue: argEnum?.[formattedValue]?.label || formattedValue,
        valueEnums: Object.keys(argEnum || {}).map(field => ({ ...argEnum[field], value: field })),
        unit: rule?.unit || '',
        max: rule?.max,
        min: rule?.min,
        pushMessageType,
        ...omit(get(argEnum, formattedValue, {}), ['label']),
      };
    }
    return r;
  }, { switchStatus: [], data: {}, dataIndexs: [] });
  // 断路器异化处理逻辑
  if (deviceType === 'circuitBreaker') {
    const tmpKeys = ['A_temperature', 'B_temperature', 'C_temperature', 'N_temperature'];
    const maxValue = Math.max(...tmpKeys.map(key => res.data[key]?.value || 0));
    res.data['N_temperature'].value = maxValue;
    res.dataIndexs = [
      'voltameter',
      'active_power',
      'power_factor',
      'N_temperature',
    ]
  }
  return res;
}

// 解析message
export const parseMessage = async (message) => {
  try {
    const info = JSON.parse(message) || {};
    const { device_id: deviceId, data = {}, device_type: deviceType } = info;
    const { model: deviceModel, data: tmp } = data || {};
    const { type: dataType, data: deviceData = {}} = tmp || {};
    const deviceFieldsMap = await getDeviceModelConf();
    const dict = deviceFieldsMap[deviceModel];

    const res = {
      deviceId,
      deviceModel,
      deviceType,
      deviceTypeName: deviceTypes[deviceType],
    };

    if (!dict)
      throw new Error(`未找到对应的设备模型${deviceModel}, dataType: ${dataType}`);
    console.log(deviceId)
    const normalizeDeviceData = transformMessageData(deviceData, dict);
    return {
      ...res,
      ...normalizeDeviceData,
    };

  } catch (e){
    console.error(e);
    return {};
  }
}

// 格式化下发消息的值
const formatteCmdMessageValue = (pushMessageType, dataIndex, cmdContent) => {
  const formatte = messageValueFormatteRule[`${pushMessageType}-${dataIndex}`];
  switch (formatte) {
    case 'int':
      return parseInt(cmdContent);
    case 'float':
      return parseFloat(cmdContent);
    case 'boolen':
      return !!cmdContent;
    default:
      return `"${cmdContent}"`;
  }
}

// 生成下发消息内容部分
const generateCmdMessageContent = ({ pushMessageType, dataIndex, cmdContent }) => {
  return `{
    "type": "${pushMessageType}",
    "data": {
      "${dataIndex}": ${formatteCmdMessageValue(pushMessageType, dataIndex, cmdContent)}
    }
  }`
}

// 生成下发消息体
export const generateCmdMessage = ({ orgCode, gatewayType, gatewayCode, deviceType, deviceId, modelNumber }, cmdConf) => {
  return `{
    "tenant_id": "${orgCode}",
    "gateway_type": "${gatewayType}",
    "gateway_id": "${gatewayCode}",
    "device_type": "${deviceType}",
    "device_id": "${deviceId}",
    "model_number": "${modelNumber}",
    "message_type": "dnlink",
    "message": ${generateCmdMessageContent(cmdConf)}
  }`
}