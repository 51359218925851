// 公共APi
import request from '@/utils/request' // 请求拦截器

// 文档上传
export function Admin_Oss_Document_Upload_Api(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `${process.env.VUE_APP_BASE_API_API}/api/oss/document/upload`,
    method: 'POST',
    data
  })
}

// 图片文件上传
export function Admin_Oss_Image_Upload_Api(data) {
  return request({
    url: `${process.env.VUE_APP_BASE_API_API}/api/oss/image/upload`,
    method: 'POST',
    data
  })
}

// 数字字典-下拉框
export function selectList(type) {
  return request({
    url: `${process.env.VUE_APP_BASE_API_API}/api/sys-dict/checkBox?type=${type}`,
    method: 'POST'
  })
}

export async function getDict(types) {
  const { data } = await request({
    method: 'POST',
    url: `${process.env.VUE_APP_BASE_API_API}/api/sys-dict/checkBox/multi`,
    data: {
      types,
    }
  });
  return data
}

// 获取签名
export const signature = (data) => request({ url: '/api/oss/signature/upload/v1', method: "POST", data });

// 批量下载
export const batchDownload = (data) => request({ url: '/api/oss/signature/download/batch/v1', method: "POST", data });

// 数据分析-在线人数
export const userCount = () => request({ url: '/api/tenant/userCount', method: "GET" });

