import Vue from 'vue'
import {
  Button, // 按钮
  ButtonGroup, // 按钮组
  Form, // 表单
  FormItem, // 表单子项
  Input, // input
  InputNumber, // input-number
  Message, // 消息提示
  Container, // 布局容器
  Header, // 头部
  Aside, // 侧边容器
  Footer, // 脚部
  Main, // 右边内容容器
  Menu, // 菜单
  Submenu, // 二级菜单
  MenuItemGroup, // 菜单组
  MenuItem, // 菜单子项
  Breadcrumb, // 面包屑
  BreadcrumbItem, // 面包屑子项
  Card, // 卡片
  Row, // row栅格容器
  Col, // 栅格
  Table, // 表格
  TableColumn, // 表格子项
  Switch, // 开关
  Tooltip, // 文字提示
  Pagination, // 分页
  Dialog, // 模态框
  Dropdown, // 下拉菜单
  DropdownMenu, // 下拉菜单
  DropdownItem, // 下拉菜单
  MessageBox, // 消息提醒
  Tag, // 标签
  Tree, // 树形结构
  Select, // 下拉选择器
  Option, // 下拉选择器子项
  Cascader, // 可搜索选择器/输入框
  Alert, // Alert 警告
  Tabs, // 选项卡
  TabPane, // 选项卡子项
  Step, // Steps 步骤条
  Steps, // Steps 步骤条子项
  Checkbox, // 复选款
  CheckboxGroup, // 复选框组
  CheckboxButton, // 按钮型复选款
  Upload, // 上传
  Timeline, // 时间线
  TimelineItem, // 时间线子集
  Image, // Image图片
  Avatar, // 头像
  Loading, // 加载动画
  TimePicker, // 时间选择器
  Divider, // 分割线
  DatePicker, // 时间选择器
  Popover, // Popover弹窗
  PageHeader, // 页头
  Radio, // 单选
  RadioGroup, // 单选组
  RadioButton, // 按钮形单选
  ColorPicker, // 颜色选择器
  Drawer, // 抽屉
  Empty, // 空状态
  Popconfirm, // 气泡确认框
  Icon,
  TimeSelect,
  Transfer,
  Descriptions,
  DescriptionsItem,
  Scrollbar,
  Progress
} from 'element-ui'

Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Footer)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)
Vue.use(Upload)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Image)
Vue.use(Avatar)
Vue.use(Loading)
Vue.use(TimePicker)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(PageHeader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(ColorPicker)
Vue.use(Drawer)
Vue.use(Empty)
Vue.use(Popconfirm)
Vue.use(Icon)
Vue.use(TimeSelect)
Vue.use(Transfer)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Scrollbar)
Vue.use(Progress)

// 将弹框组件全局挂在到VUe原形实例
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$prompt = MessageBox.prompt
