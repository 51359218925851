import { getMqttInfo, getMqttClient, parseMessage, generateCmdMessage, getTopicStrFromDevice } from '@/utils/device';

let mqttClient = null;

export default {
  // namespaced: true,
  state: {
    devices: {},
    mqttClient: null
  },
  mutations: {
    initMQTT(state, { username, password }) {
      if (mqttClient) { return; }
      mqttClient = getMqttClient({ username, password });
      mqttClient.on('message', async (_, message) => {
        const formattedData = await parseMessage(message.toString());
        const {
          switchStatus = [],
          data = {},
          dataIndexs = [],
        } = state.devices[formattedData.deviceId] || {};

        const newSwitchStatus = [];
        if (!switchStatus.length) {
          newSwitchStatus.push(...formattedData.switchStatus);
        } else if (formattedData.switchStatus.length) {
          switchStatus.forEach(item => {
            const tmp = formattedData.switchStatus.find(it => it.dataIndex === item.dataIndex);
            if (tmp) {
              newSwitchStatus.push(tmp);
            } else {
              newSwitchStatus.push(item);
            }
          });
        }

        state.devices = {
          ...state.devices,
          [formattedData.deviceId]: {
            deviceId: formattedData.deviceId,
            deviceModel: formattedData.deviceModel,
            deviceType: formattedData.deviceType,
            deviceTypeName: formattedData.deviceTypeName,
            switchStatus: newSwitchStatus,
            data: {
              ...data,
              ...formattedData.data,
            },
            dataIndexs: dataIndexs.length ? dataIndexs : formattedData.dataIndexs,
          }
        };
        mqttClient.on('error', (error) => {
          console.log(error.message);
        });
      });
    },
    subscribe(state, { devices, orgCode }) {
      devices.forEach(item => {
        mqttClient.subscribeAsync(getTopicStrFromDevice(orgCode, item));
      })
    },
    unsubscribe(state, { devices, orgCode }) {
      devices.forEach(item => {
        mqttClient.unsubscribeAsync(getTopicStrFromDevice(orgCode, item));
      })
    },
    sendCommand(state, { deviceInfo, cmdConf, orgCode }) {
      if (!mqttClient) return;
      mqttClient.publish('$yuna_sys/dnlink_bus', generateCmdMessage({
        orgCode,
        ...deviceInfo,
      }, cmdConf));
    }
  },
  actions: {

  }
};
