<!-- 底部（分页） -->
<template>
  <div id="Footer">
    <el-pagination
      :current-page="CommonCurrentPage"
      :page-sizes="CommonPageSizes"
      :page-size="CommonPageSize"
      :total="CommonTotal"
      layout="prev, pager, next, sizes, total"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Name',
  data() {
    return {}
  },
  /* HTML DOM加载后马上执行的，如赋值*/
  computed: {
    // 引入全局头部模块
    ...mapState('Common', [
      'CommonCurrentPage', // 当前页数
      'CommonPageSizes', //  每页显示个数选择器的选项设置
      'CommonPageSize', // 每页显示条目个数
      'CommonTotal' // 总条目数
    ])
  },

  /* 观察Vue实例上的数据变动 */
  watch: {},

  /* 模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图 */
  created() {},

  /* 模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作 */
  mounted() {},

  /* methods则必须要有一定的触发条件才能执行 */
  methods: {
    // 引入 Common->mapMutations
    ...mapMutations('Common', [
      'GetHandleSizeChange', // 获取一页展示多少条数据
      'GetHandleCurrentChange' // 获取当前页
    ]),
    // pageSize 改变时会触发
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.GetHandleSizeChange(val) // 获取一页展示多少条数据
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.GetHandleCurrentChange(val) // 获取当前页
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
