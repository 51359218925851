<template>
  <el-select ref="mySelect"
             :value="valueFilter(value)"
             :placeholder="placeholder"
             :multiple="$attrs['show-checkbox']"
             :disabled="disabled"
             :filterable="$attrs['filterable']"
             :collapse-tags="$attrs['collapse-tags']"
             :filter-method="remoteMethod"
             :clearable="clearable"
             :size="size"
             @change="selectChange"
             @clear="selectClear"
             style="width: 100%;"
  >
    <template slot="empty">
      <div class="selecTree">
        <el-tree ref="myTree"
                 :data="data"
                 :props="defaultProps"
                 :show-checkbox="$attrs['show-checkbox']"
                 :check-strictly="$attrs['check-strictly']"
                 :icon-class="$attrs['icon-class']"
                 :lazy="$attrs['lazy']"
                 :load="$attrs['load']"
                 :node-key="defaultProps.value"
                 :highlight-current="true"
                 :default-expanded-keys="defaultExpandedKeys"
                 :filter-node-method="filterNode"
                 @check-change="handleCheckChange"
                 @node-click="handleNodeClick"
        >
          <template slot-scope="{ node, data }">
            <slot :node="node" :data="data">
              <span class="slotSpan">
                <span>
                  {{ data[defaultProps.label] }}
                  <b v-if="$attrs['show-count'] != undefined && data[defaultProps.children]">({{ data[defaultProps.children].length }})</b>
                </span>
              </span>
            </slot>
          </template>
        </el-tree>
      </div>
    </template>
  </el-select>
</template>
<script>
import {
  selectList
} from '@/api/ComminApi';
export default {
  props: {
    value: {
      type: String || Array,
      default: () => {}
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dataCode: {
      type: String,
      default: 'deviceCode'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    showDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultExpandedKeys: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'code' // 树节点的值字段
      }
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (!val || !val?.length) {
          this.selectClear(true);
        }
      },
    },
  },
  created() {
    // this.propsInit();
  },
  mounted() {
    this.getOptions()
    // setTimeout(this.initData, 10);
  },
  beforeUpdate() {
    // this.propsInit();
    // this.initData();
  },

  methods: {
    getOptions() {
      selectList(this.dataCode).then(res => {
        if (res.code === 200) {
          this.data = res.data?.[0]?.children || []
          this.initData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    initData() {
      if (this.$attrs['show-checkbox'] === undefined) {
        let newItem = this.recurrenceQuery(
          this.data,
          this.defaultProps.value,
          this.value
        );
        if (newItem.length) {
          if (this.defaultProps.value && newItem[0][this.defaultProps.value]) {
            this.defaultExpandedKeys = [newItem[0][this.defaultProps.value]];
          }
          this.$nextTick(() => {
            this.$refs.myTree.setCurrentNode(newItem[0]);
          });
        }
      } else {
        let newValue = JSON.parse(JSON.stringify(this.value));
        if (!(newValue instanceof Array)) {
          newValue = [newValue];
        }
        if (newValue?.length) {
          let checkList = newValue.map((key) => {
            if (key) {
              let newItem = this.recurrenceQuery(
                this.data,
                this.defaultProps.value,
                key
              );
              return newItem[0] || '';
            }
          });
          if (checkList?.length) {
            let defaultExpandedKeys = checkList.map(
              (item) => item?.[this.defaultProps.value || '']
            );
            if (defaultExpandedKeys.length) this.defaultExpandedKeys = defaultExpandedKeys;
            this.$nextTick(() => {
              this.$refs.myTree.setCheckedNodes(checkList);
            });
          }
        }
      }
      this.$forceUpdate();
    },
    // 多选
    handleCheckChange(data, e, ev) {
      let checkList = this.$refs.myTree.getCheckedNodes();
      let setList = null;
      if (checkList.length) {
        setList = checkList?.map((item) => item[this.defaultProps.value]);
      }
      this.$emit('input', setList);
      // 共三个参数，依次为：传递给 data 属性的数组中该节点所对应的对象、节点本身是否被选中、节点的子树中是否有被选中的节点
      this.$emit('change', data, e, ev);
    },
    // 单选事件
    handleNodeClick(data, e) {
      if (!(this.$attrs['select-last-node'] === undefined)) {
        if (data[this.defaultProps.children] && data[this.defaultProps.children]?.length) {
          return false;
        }
      }
      if (this.$attrs['show-checkbox'] === undefined) {
        this.$emit('input', data[this.defaultProps.value]);
        this.$refs.mySelect.blur();
      }
      this.$emit('change', data.code, e);
    },
    //   递归查找通用方法
    recurrenceQuery(list, key, value) {
      // if (!list || !key || !value) return [];
      let queryData = [];
      list?.map((item) => {
        if (item[this.defaultProps.children] && item[this.defaultProps.children].length) {
          queryData.push(
            ...this.recurrenceQuery(item[this.defaultProps.children], key, value)
          );
        }
        if (item[key] == value) {
          queryData.push(item);
        }
        return item;
      });
      return queryData;
    },
    selectChange(e) {
      if (this.$attrs['show-checkbox'] !== undefined) {
        let checkList = e.map((key) => {
          let newItem = this.recurrenceQuery(this.data, this.defaultProps.label, key);
          return newItem[0] || '';
        });
        this.$refs.myTree.setCheckedNodes(checkList);
        this.$emit('input', e);
      }
    },
    selectClear(flag) {
      if (this.$attrs['show-checkbox'] === undefined) {
        if (!flag) this.$emit('input', null);
        this.$refs.myTree.setCurrentKey(null);
        this.$emit('change', null);
      } else {
        if (!flag) this.$emit('input', []);
        this.$refs.myTree.setCheckedKeys([]);
      }
      this.remoteMethod('');
    },
    getCheckedNodes() {
      if (
        this.value !== null &&
        this.value !== undefined &&
        this.value !== ''
      ) {
        return this.$refs.myTree.getCheckedNodes();
      }
      return [];
    },
    getCurrentNode() {
      if (
        this.value !== null &&
        this.value !== undefined &&
        this.value !== ''
      ) {
        return this.$refs.myTree.getCurrentNode();
      }
      return null;
    },
    valueFilter(val) {
      if (this.$attrs['show-checkbox'] === undefined) {
        let res = '';
        if (!val && this.showDefault) {
          val = this.data?.[0]?.code
          this.$emit('input', val);
        }
        [res] = this.recurrenceQuery(this.data, this.defaultProps.value, val);
        return res?.[this.defaultProps.label] || '';
      } else {
        if (!val?.length) return [];
        let res = val.map((item) => {
          let [newItem] = this.recurrenceQuery(
            this.data,
            this.defaultProps.value,
            item
          );
          return newItem?.[this.defaultProps.label] || '';
        });
        if (!res?.length) return [];
        res = res.filter((item) => item);
        return res;
      }
    },
    propsInit() {
      this.label = this.defaultProps.label || 'label';
      this.value = this.value || 'value';
      this.children = this.children || 'children';
      if (
        this.$attrs['select-last-node'] !== undefined &&
        !this.disabled
      ) {
        this.disabled = (data) => data?.[this.children]?.length;
        this.$attrs['check-strictly'] = true;
      }
    },

    remoteMethod(query) {
      this.$refs.myTree.filter(query);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
  },
};
</script>
