import { Notification } from "element-ui";
import CryptoJS from 'crypto-js'

var socket = null; // 实例对象
var lockReconnect = false; // 是否真正建立连接
var timeout = 60 * 1000; // 20秒一次心跳
var timeoutObj = null; // 心跳倒计时
var serverTimeoutObj = null; // 服务心跳倒计时
var timeoutnum = null; // 断开 重连倒计时

const initWebSocket = async () => {
  if ("WebSocket" in window) {
    const client_secret = CryptoJS.enc.Utf8.parse(
      "e9b1a0f23f9d4c6b8c1e0f8a12e34d5e"
    ); // 16 字节的密钥表示 128 位
    const current_time = String(+new Date());
    const token = btoa(
      "12e34d5e" +
        CryptoJS.AES.encrypt(current_time, client_secret, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }).toString()
    );
    // const wsUrl = "wss://iot.iyunang.com/iot/websocket/realtime/data";
    const wsUrl =
      "wss://iot.iyunang.com/iot/websocket/realtime/data/point-value";
    socket = new WebSocket(wsUrl + `?token=${token}`);
    socket.onerror = webSocketOnError;
    socket.onmessage = webSocketOnMessage;
    socket.onclose = closeWebsocket;
    socket.onopen = openWebsocket;
  } else {
    Notification.error({
      title: "错误",
      message: "您的浏览器不支持websocket，请更换Chrome或者Firefox",
    });
  }
};

// 建立连接
const openWebsocket = (e) => {
  console.log("建立链接-----------", e);
  // socket.send(
  //   JSON.stringify({
  //     // 连接成功将，用户ID传给服务端
  //     clientId: "e1c352db5da945b3b4a51f3c5a3f7f95",
  //     kind: "POINT",
  //     // codes: ["02xkqnat2x43", "0av7of5jzr", "0brlabrn97", "1314"]
  //     codes: [],
  //   })
  // );
  start();
};

const start = () => {
  // 开启心跳
  console.log("建立心跳-----------");
  timeoutObj && clearTimeout(timeoutObj);
  serverTimeoutObj && clearTimeout(serverTimeoutObj);
  timeoutObj = setTimeout(function () {
    // 这里发送一个心跳，后端收到后，返回一个心跳消息
    if (socket.readyState == 1) {
      console.log("连接正常-----------");

      // 如果连接正常
      //  socket.send("heartbeat");
    } else {
      // 否则重连
      reconnect();
    }
    serverTimeoutObj = setTimeout(function () {
      // 超时关闭
      socket.close();
    }, timeout);
  }, timeout);
};

// 重新连接
const reconnect = () => {
  console.log("重连-----------");

  if (lockReconnect) {
    return;
  }
  lockReconnect = true;
  // 没连接上会一直重连，设置延迟避免请求过多
  timeoutnum && clearTimeout(timeoutnum);
  timeoutnum = setTimeout(function () {
    // 新连接
    initWebSocket();
    lockReconnect = false;
  }, 1000);
};

// 重置心跳
const reset = () => {
  console.log("重置-----------");
  // 清除时间
  clearTimeout(timeoutObj);
  clearTimeout(serverTimeoutObj);
  // 重启心跳
  start();
};

const sendWebsocket = (e) => {
  console.log("发送-----------", e);
  socket.send(
    JSON.stringify({
      // 连接成功将，用户ID传给服务端
      clientId: window.sessionStorage.getItem("Authorization"),
      kind: "POINT",
      codes: e,
      // codes: [],
    })
  );
};

const webSocketOnError = (e) => {
  initWebSocket();
  reconnect();
};

// 服务器返回的数据
const webSocketOnMessage = (e) => {
  console.log("返回-----------", e);
  // 判断是否登录
  //   if (getToken()) {
  // window自定义事件[下面有说明]
  window.dispatchEvent(
    new CustomEvent("onmessageWS", {
      detail: {
        data: JSON.parse(e?.data),
      },
    })
  );
  //   }
  reset();
};

const closeWebsocket = (e) => {
  reconnect();
};

// 断开连接
const close = () => {
  console.log("关闭-----------");
  // WebSocket对象也有发送和关闭的两个方法，只需要在自定义方法中分别调用send()和close()即可实现。
  socket.close();
  clearTimeout(timeoutnum);
  clearTimeout(timeoutObj);
  clearTimeout(serverTimeoutObj);
};
// 具体问题具体分析,把需要用到的方法暴露出去
export default { initWebSocket, sendWebsocket, webSocketOnMessage, close };
