var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Layout" } },
    [
      _c(
        "el-container",
        [
          _c("Aside"),
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { attrs: { height: _vm.CommonHeaderHeight } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "el-zoom-in-top" } },
                    [
                      _c("Header", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.CommonHeadershow,
                            expression: "CommonHeadershow",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                {
                  class: _vm.CommonElMainHeightStart ? "yesFooter" : "noFooter",
                  attrs: { id: "ElMain" },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "el-zoom-in-bottom" } },
                    [
                      _c("router-view", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.CommonElMainshow,
                            expression: "CommonElMainshow",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.CommonElMainHeightStart
                ? _c("el-footer", [_c("Footer")], 1)
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }