import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "element-ui/lib/theme-chalk/index.css";
// 按需引入Element
import "./plugins/element.js";
// 引入DataV
import dataV from "@jiaminghi/data-view";
// 引入全局公共样式
import "./assets/Sass/ComminSass.scss";
// 引入全局公用方法
import Commonmethods from "@/utils/Commonmethods";
// fade/zoom 等
import "element-ui/lib/theme-chalk/base.css";
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
// vueQr https://blog.csdn.net/weixin_42601136/article/details/114839489 https://blog.csdn.net/qq826303461/article/details/117321812
import vueQr from "vue-qr";
import VueClipboard from "vue-clipboard2";
import CommonSelection from "./components/select.vue";
import SearchTree from './components/searchTree.vue'

import "element-ui/lib/theme-chalk/index.css";

import "vue-cron-generator/src/styles/global.css";
import Element from "element-ui";
import i18n from "./i18n/index";

// import "@/assets/iconfont/index.js";

// 使用iconfont的fontClass
import "@/assets/iconfont/iconfont.css"
// 使用iconfont的symbol
import "@/assets/iconfont/iconfont.js"

// 引入svg图标
import SvgIcon from "./components/SvgIcon.vue";

Vue.use(Element, {
  size: localStorage.getItem("size") || "small", // set element-ui default size
  i18n: (key, value) => {
    return i18n.t(key, value);
  },
});

Vue.use(VueClipboard);
Vue.use(CommonSelection);
Vue.use(SearchTree)
Vue.component("CommonSelection", CommonSelection);
Vue.component("SearchTree", SearchTree);
// 关闭生产提示
Vue.config.productionTip = false;
// 使用DataV的组件
Vue.use(dataV);
// 使用vueQr
Vue.use(vueQr);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component("SvgIcon", SvgIcon);
Vue.prototype.$Commonmethods = Commonmethods;

import webScoket from "@/utils/webScoket";
Vue.prototype.$webScoket = webScoket;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive("defaultSelect", {
  componentUpdated(el, bindings) {
    const [values, options, prop, defaultProp, defaultValue] = bindings.value;
    const indexs = [];
    const tempData = values.map(item => options.find(op => op[prop] === item));
    tempData.forEach((item, index) => {
      if (item[defaultProp] === defaultValue) {
        indexs.push(index);
      }
    });
    const dealStyle = function(tags) {
      tags.forEach((el, index) => {
        if (indexs.includes(index) && ![...el.classList].includes("select-tag-close-none")) {
          el.classList.add("none");
        }
      });
    };
    const tags = el.querySelectorAll(".el-tag__close");
    if (tags.length === 0) {
      setTimeout(() => {
        const tagTemp = el.querySelectorAll(".el-tag__close");
        dealStyle(tagTemp);
      });
    } else {
      dealStyle(tags);
    }
  }
});

// 只需修改xxxxx值，其余无需修改
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'tenantInfo' || key === 'tenantName') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

