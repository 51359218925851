<!-- Aside -->
<template>
  <div id="Aside">
    <transition name="el-zoom-in-center">
      <div v-show="CommonAsideShow" class="transition-box">
        <el-aside
          :width="
            CommonShrinkageStart
              ? `${CollapseWidth}` + 'px'
              : `${AsideWidth}` + 'px'
          "
        >
          <div class="logo_box">
            <div
              v-if="CommonLogoUrl !== ''"
              class="flex flex-col justify-center items-center h-[100%]"
            >
              <div class="h-8 pt-1 text-center">
                <el-image
                  :style="CommonShrinkageStart ? 'height: 80%' : 'height: 100%'"
                  :src="CommonLogoUrl"
                  fit="contain"
                />
              </div>
              <h4 v-if="!CommonShrinkageStart" class="text-lg">云卬科技</h4>
            </div>
            <div v-else class="logo">
              <h5>Logo</h5>
              <h4>{{ CommonShrinkageStart ? 70 : 300 }}* 70</h4>
            </div>
          </div>
          <div class="menu_box">
            <el-menu
              :background-color="CommonMenuBackgroundColor"
              :text-color="CommonMenuTextColor"
              :active-text-color="CommonMenuActiveTextColor"
              :unique-opened="CommonUniqueOpened"
              :collapse="CommonShrinkageStart"
              :collapse-transition="CommonCollapseTransition"
              :default-active="CommonDefaultActive"
              @select="HandleMenuSelect"
            >
              <template v-for="item in CommonAsideMenusList">
                <template v-if="item.children">
                  <el-submenu :key="item.index" :index="item.path">
                    <template #title>
                      <i :class="item.Icon" />
                      <span>{{ item.authName }}</span>
                    </template>
                    <template v-for="subItem in item.children">
                      <el-submenu
                        v-if="subItem.children"
                        :key="subItem.id"
                        :index="subItem.path"
                      >
                        <template #title>{{ subItem.authName }}</template>
                        <el-menu-item
                          v-for="(threeItem, i) in subItem.children"
                          :key="i"
                          :index="threeItem.path"
                          >{{ threeItem.authName }}</el-menu-item
                        >
                      </el-submenu>
                      <el-menu-item
                        v-else-if="subItem.show"
                        :key="subItem.index"
                        :index="subItem.path"
                        >{{ subItem.authName }}</el-menu-item
                      >
                    </template>
                  </el-submenu>
                </template>
                <template v-else>
                  <el-menu-item :key="item.index" :index="item.path">
                    <i :class="item.Icon" />
                    <template #title>{{ item.authName }}</template>
                  </el-menu-item>
                </template>
              </template>
            </el-menu>
          </div>
        </el-aside>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Aside",
  data() {
    return {
      // 侧边默认宽度
      AsideWidth: 200,
      // 侧边收起宽度
      CollapseWidth: 70,
      // 导航列表
      MenusList: [],
    };
  },
  /* HTML DOM加载后马上执行的，如赋值*/
  computed: {
    // 引入全局导航配置模块
    ...mapState("Common", [
      "CommonShrinkageStart", // 导航是否展开
      "CommonAsideShow", // Aside过度动画
      "CommonLogoUrl", // logo图片地址
      "CommonMenuBackgroundColor", // 导航背景
      "CommonMenuTextColor", // 导航文字颜色
      "CommonMenuActiveTextColor", // 导航文字选中
      "CommonAsideMenusList", // 导航数据
      "CommonDefaultActive", // 导航默认选择
      "CommonUniqueOpened", // 是否只保持一个子菜单的展开
      "CommonCollapseTransition", // 是否开启折叠动画
    ]),
  },

  /* 观察Vue实例上的数据变动 */
  watch: {},

  /* 模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图 */
  created() {},

  /* 模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作 */
  mounted() {
    if (window.sessionStorage.getItem("CommonDefaultActive")) {
      this.GetCommonDefaultActive(
        window.sessionStorage.getItem("CommonDefaultActive")
      ); // 页面加载完成从sessionStorage获取刷新前点击的激活状态
    } else {
      this.GetCommonDefaultActive("/CarbonManagement/index");
      window.sessionStorage.setItem(
        "CommonDefaultActive",
        "/CarbonManagement/index"
      );
    }
    this.ChangeAsideShow(true); // 导航过度动画状态修改
    this.ChangeHeadershow(true); // 头部过度动画状态修改
    this.ChangeElMainshow(true); // 内容过度动画状态修改
  },

  /* methods则必须要有一定的触发条件才能执行 */
  methods: {
    // 引入 Common->mapMutations
    ...mapMutations("Common", [
      "ChangeAsideShow", // 导航过度动画状态修改
      "ChangeHeadershow", // 头部过度动画状态修改
      "ChangeElMainshow", // 内容过度动画状态修改
      "GetCommonDefaultActive", // 获取点击导航
    ]),
    // 获取菜单
    HandleMenuSelect(index, indexPath) {
      console.log("获取菜单", index, indexPath, window.location);
      if (indexPath[1] != "/statistics") {
        this.$router.push(indexPath[1]);
      } else {
        window.open(origin + "/#/statistics");
      }
      this.GetCommonDefaultActive(indexPath[1]);
      window.sessionStorage.setItem("CommonDefaultActive", indexPath[1]);
    },
    // 点击二级导航，获取路由（点亮）保存导航激活状态
    ClickDefaultActive(ClickDefaultActive) {
      console.log("点击二级导航", ClickDefaultActive);
      window.sessionStorage.setItem("CommonDefaultActive", ClickDefaultActive);
      this.GetCommonDefaultActive(ClickDefaultActive);
    },
  },
};
</script>

<style lang="scss" scoped>
#Aside {
  .el-aside {
    height: 100vh;
  }
  .logo_box {
    height: 70px;
    .el-image {
      width: 100%;
      height: 70px;
    }
    .logo {
      background: transparent;
      height: 70px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h5,
      h4 {
        margin: 0;
      }
    }
  }
  .menu_box {
    text-align: left;
    > .el-menu {
      border-right: none;
    }
  }
}
</style>
