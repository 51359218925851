var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Footer" } },
    [
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.CommonCurrentPage,
          "page-sizes": _vm.CommonPageSizes,
          "page-size": _vm.CommonPageSize,
          total: _vm.CommonTotal,
          layout: "prev, pager, next, sizes, total",
          background: "",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }