import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate' // 将vuex某些数据做持久化
// 引入全局公共Vuex模块
import Common from './modules/Common'
import Mqtt from './modules/Mqtt'
Vue.use(Vuex)

// Vuex数据持久化key
const PERSISTENCE_ARR = []
export default new Vuex.Store({
  // 将vuex某些数据做持久化
  plugins: [
    createPersistedState({
      // 默认存储在localStorage 现改为sessionStorage
      storage: window.sessionStorage,
      paths: PERSISTENCE_ARR
    })
  ],
  modules: {
    Common, // 全局公共Vuex模块
    Mqtt
  }
})
