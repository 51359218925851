var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Header" } }, [
    _c("div", { staticClass: "header_box" }, [
      _c(
        "div",
        {
          staticClass: "header_left",
          style: { height: _vm.CommonHeaderHeight },
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.CommonShrinkageStart ? "菜单展开" : "菜单收起",
                placement: "top-start",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  type: "text",
                  icon: _vm.CommonShrinkageStart
                    ? "el-icon-s-unfold"
                    : "el-icon-s-fold",
                },
                on: { click: _vm.FoldingClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "header_right",
          style: { height: _vm.CommonHeaderHeight },
        },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.HandleCommand } },
            [
              _c(
                "div",
                { staticClass: "avatar_box" },
                [
                  _c("el-avatar", {
                    staticClass: "avatar",
                    attrs: { icon: "el-icon-user-solid" },
                  }),
                  _vm._v(" admin"),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "3" } }, [
                    _c("i", { staticClass: "el-icon-switch-button" }),
                    _vm._v("退出账号"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }