var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "svg",
      {
        staticClass: "icon",
        class: _vm.svgClass,
        attrs: { "aria-hidden": "true" },
      },
      [_c("use", { attrs: { "xlink:href": _vm.iconName } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }