<template>
  <!-- Icon组件 -->
  <div>
    <svg :class="svgClass" class="icon" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CommonIcon',
  props: {
    svgClass: {
      type: String,
      default: '', // 默认值
    },
    iconName: {
      type: String,
      required: true // 必传
    }
  },
  computed: {
    computedSvgClass() {
      return `icon ${this.svgClass}`;
    },
    computedIconName() {
      return `#${this.iconName}`;
    }
  }
};
</script>

<style lang="scss">
.icon {
  width: 16px;
  height: 16px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  cursor: pointer;
}
</style>
