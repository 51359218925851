/*
* 全局通用方法
*/
// 引入moment
const moment = require('moment')
import { default as request } from '@/utils/request';
export default {
  /**
   * 非空验证
   * value: 值
   * type: 类型 String:字符串，Number: 数字
   * text: 提示文字
  **/
  isEmpty(value, type, text) {
    if (type === 'String') {
      if (value) {
        return value
      } else {
        return text
      }
    } else if (type === 'Number') {
      if (value) {
        return value
      } else {
        return 0
      }
    }
  },
  // 利用js递归将最后一级空数组变成undefined,级联选择器选择有多少层展示多少层
  getTreeData(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].childList.length < 1) {
        // 最后一级没有数据将children变成undefined
        data[i].childList = undefined
      } else {
        // children不为空时继续调用该方法
        this.getTreeData(data[i].childList)
      }
    }
    return data
  },
  /**
   * 时间转换通用方法
   * date: 时间
   * format：格式 stamp：时间戳（毫秒级），YMD: 年月日例如：2022-11-12，YMDMHS: 年月日时分秒例如：2022-11-12 00:00:00
   * reverse: 如果时间解析失败会有Invalid date字符串，那么跟上自定义字符串则展示自定义字符串
   * */
  TransformationTime(date, format, reverse) {
    let DisposeDate = null
    switch (format) {
      case 'stamp':
        DisposeDate = moment(date).format('x')
        break
      case 'YMD':
        DisposeDate = moment(date).format('YYYY-MM-DD')
        break
      case 'YMDMH':
        DisposeDate = moment(date).format('YYYY-MM-DD HH:mm')
        break
      case 'YMDMHS':
        DisposeDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        break
    }
    if (format === 'stamp') {
      return DisposeDate
    } else {
      if (!isNaN(DisposeDate)) {
        return reverse
      } else if (DisposeDate === 'Invalid date') {
        return reverse
      } else if (DisposeDate === null) {
        return reverse
      } else {
        return DisposeDate
      }
    }
  },

  // 将一个数组分割成多个数组
  group(array, subGroupLength) {
    let index = 0
    const newArray = []
    while (index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength))
    }
    return newArray
  },

  // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
  beforeUpload(file) {
    console.log('上传文件之前的钩子，参数为上传的文件sssssssssss', file)
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!')
    }
    return isLt2M
  },

  // 不确定是多少位小数 num为传入的值，n为保留的小数位
  FomatFloat(num, n) {
    var f = parseFloat(num)
    if (isNaN(f)) {
      return false
    }
    f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n) // n 幂
    var s = f.toString()
    var rs = s.indexOf('.')
    // 判定如果是整数，增加小数点再补0
    if (rs < 0) {
      rs = s.length
      s += '.'
    }
    while (s.length <= rs + n) {
      s += '0'
    }
    return s
  },

  async getFilesById(id) {
    try {
      const { downLoadUrl } = await request({
        url: '/api/oss/signature/download/v1',
        data: {
          fileId: id
        },
        method: 'POST'
      });
      return downLoadUrl
    } catch (error) {
      this.$message.error(error)
    }
  }
}
