import request from '@/utils/request' // 请求拦截器

// 登录
export function Login_API(data) {
  return request({
    url: `${process.env.VUE_APP_BASE_API_API}/api/login`,
    method: 'POST',
    data
  })
}

// 登出 /api/logout
export function Logout_API(data) {
  return request({
    url: `${process.env.VUE_APP_BASE_API_API}/api/logout`,
    method: 'POST',
    data
  })
}
