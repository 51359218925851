var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "mySelect",
      staticStyle: { width: "100%" },
      attrs: {
        value: _vm.valueFilter(_vm.value),
        placeholder: _vm.placeholder,
        multiple: _vm.$attrs["show-checkbox"],
        disabled: _vm.disabled,
        filterable: _vm.$attrs["filterable"],
        "collapse-tags": _vm.$attrs["collapse-tags"],
        "filter-method": _vm.remoteMethod,
        clearable: _vm.clearable,
        size: _vm.size,
      },
      on: { change: _vm.selectChange, clear: _vm.selectClear },
    },
    [
      _c("template", { slot: "empty" }, [
        _c(
          "div",
          { staticClass: "selecTree" },
          [
            _c("el-tree", {
              ref: "myTree",
              attrs: {
                data: _vm.data,
                props: _vm.defaultProps,
                "show-checkbox": _vm.$attrs["show-checkbox"],
                "check-strictly": _vm.$attrs["check-strictly"],
                "icon-class": _vm.$attrs["icon-class"],
                lazy: _vm.$attrs["lazy"],
                load: _vm.$attrs["load"],
                "node-key": _vm.defaultProps.value,
                "highlight-current": true,
                "default-expanded-keys": _vm.defaultExpandedKeys,
                "filter-node-method": _vm.filterNode,
              },
              on: {
                "check-change": _vm.handleCheckChange,
                "node-click": _vm.handleNodeClick,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              _c("span", { staticClass: "slotSpan" }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(data[_vm.defaultProps.label]) +
                                      " "
                                  ),
                                  _vm.$attrs["show-count"] != undefined &&
                                  data[_vm.defaultProps.children]
                                    ? _c("b", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              data[_vm.defaultProps.children]
                                                .length
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          },
                          { node: node, data: data }
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }