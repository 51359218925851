<!-- Layout -->
<template>
  <div id="Layout">
    <el-container>
      <Aside />
      <el-container>
        <el-header :height="CommonHeaderHeight">
          <transition name="el-zoom-in-top">
            <Header v-show="CommonHeadershow" />
          </transition>
        </el-header>
        <!-- 面包屑导航 -->
        <!-- <div class="breadcrumb_box">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(breadcrumb, breadcrumbIndex) in CommonBreadcrumbList" :key="breadcrumbIndex">{{ breadcrumb }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div> -->
        <el-main id="ElMain" :class="CommonElMainHeightStart?'yesFooter':'noFooter'">
          <transition name="el-zoom-in-bottom">
            <router-view v-show="CommonElMainshow" />
          </transition>
        </el-main>
        <el-footer v-if="CommonElMainHeightStart">
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Aside from './Aside.vue'
import Header from './Header.vue'
import Footer from './Footer.vue'
export default {
  name: 'Layout',
  components: {
    Aside,
    Header,
    Footer
  },
  data() {
    return {}
  },
  /* HTML DOM加载后马上执行的，如赋值*/
  computed: {
    // 引入全局头部模块
    ...mapState('Common', [
      'CommonHeaderHeight', // 默认头部高度
      'CommonHeadershow', // 头部过度动画状态
      'CommonElMainshow', // 内容过度动画状态
      'CommonBreadcrumbList', // 面包屑数组
      'CommonElMainHeightStart' // 判断是否展示底部（翻页）
    ])
  },

  /* 观察Vue实例上的数据变动 */
  watch: {
    $route(to, from) {
      // console.log('路由变化了', this.$route.path, this.$route)
      // console.log('路由变化了2222', to, from)
      const BreadcrumbList = []
      if (this.$route.meta.type === 'details') { // 判断详情重组面包屑
        BreadcrumbList.push(this.$route.matched[0].name, from.matched[1].name, to.matched[1].name)
        this.GetCommonDefaultActive(from.path) // 获取点击导航
        this.GetCommonElMainHeightStart(false)
      } else if (this.$route.meta.type === 'addData') {
        BreadcrumbList.push(this.$route.matched[0].name, from.matched[1].name, to.matched[1].name)
        this.GetCommonDefaultActive(from.path) // 获取点击导航
        this.GetCommonElMainHeightStart(false)
      } else if (this.$route.meta.type === 'editData') {
        BreadcrumbList.push(this.$route.matched[0].name, from.matched[1].name, to.matched[1].name)
        this.GetCommonDefaultActive(from.path) // 获取点击导航
        this.GetCommonElMainHeightStart(false)
      } else {
        this.$route.matched.forEach(Element => {
          BreadcrumbList.push(Element.name)
        })
        this.GetCommonDefaultActive(this.$route.path) // 获取点击导航
      }
      window.sessionStorage.setItem('BreadcrumbList', JSON.stringify(BreadcrumbList))
      this.CommonElMainHeightStartFun(this.$route.path) // 判断是否有分页
      this.GetCommonBreadcrumbList(BreadcrumbList) // 获取面包屑
      // 路由改变初始化分页数据
      this.InItCurrentStart({
        CommonCurrentPage: 1, // 当前页
        CommonPageSize: 20, // 当前页默认多少条数据
        CommonTotal: 0 // 总数居
      })
      this.ChangeAsideShow(true)
      this.ChangeHeadershow(true)
    }
  },

  /* 模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图 */
  created() {},

  /* 模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作 */
  mounted() {
    this.CommonElMainHeightStartFun(this.$route.path) // 判断是否有分页
    this.GetCommonBreadcrumbList(JSON.parse(window.sessionStorage.getItem('BreadcrumbList'))) // 获取面包屑
    this.GetCommonProcessEnvUrl(process.env.VUE_APP_BASE_API + '/' + process.env.VUE_APP_BASE_API_API) // 获取环境变量中的URL
  },

  /* methods则必须要有一定的触发条件才能执行 */
  methods: {
    // 引入 Common->mapMutations
    ...mapMutations('Common', [
      'ChangeAsideShow', // 导航过度动画状态修改
      'ChangeHeadershow', // 头部过度动画状态修改
      'GetCommonDefaultActive', // 获取点击导航
      'GetCommonBreadcrumbList', // 获取面包屑
      'GetCommonElMainHeightStart', // 判断是否展示底部（翻页）
      'InItCurrentStart', // 路由改变初始化分页数据
      'GetCommonProcessEnvUrl' // 获取环境变量中的URL
    ]),
    // 判断是否有分页
    CommonElMainHeightStartFun(path) {
      const needPagingPath = [
        '/CarbonManagement/index', // 碳排放管理/活动发布管理
        '/ContentManagement/index', // 内容管理/资讯管理
        '/SpecialManagement/index', // 专项讲解
        '/SystemManagement/UserManagement', // 内容管理/资讯管理
        '/SystemManagement/UnitManagement', // 系统管理/单位管理
        // '/Tenant/TenantList', // 租户管理/租户列表
        '/DeviceManagement/DeviceList', // 设备管理/设备列表
        '/RegularUsers/index', // 普通用户
      ];

      switch (true) {
        case needPagingPath.includes(path):
          this.GetCommonElMainHeightStart(true);
          break;
        default:
          this.GetCommonElMainHeightStart(false);
          break;
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
